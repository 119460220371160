
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "79267cf2-54c6-4912-8c65-75f4a02e9147"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/apps/core/app.config.ts"
import cfg1 from "/app/apps/legacy/app.config.ts"
import cfg2 from "/app/apps/core/app.config.ts"
import cfg3 from "/app/apps/poslovachr/app.config.ts"
import cfg4 from "/app/apps/core/app.config.ts"
import cfg5 from "/app/apps/legacy/app.config.ts"
import cfg6 from "/app/apps/core/app.config.ts"
import cfg7 from "/app/apps/generic/app.config.ts"
import cfg8 from "/app/apps/core/app.config.ts"
import cfg9 from "/app/apps/legacy/app.config.ts"
import cfg10 from "/app/apps/core/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, cfg7, cfg8, cfg9, cfg10, inlineConfig)
