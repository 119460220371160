
// @ts-nocheck
import locale__app_apps_generic_locale_en_json from "../../generic/locale/en.json";
import locale__app_apps_poslovachr_locale_en_json from "../../poslovachr/locale/en.json";
import locale__app_apps_poslovachr_locale_hr_json from "../../poslovachr/locale/hr.json";


export const localeCodes =  [
  "en",
  "hr"
]

export const localeLoaders = {
  "en": [{ key: "../../generic/locale/en.json", load: () => Promise.resolve(locale__app_apps_generic_locale_en_json), cache: true },
{ key: "../../poslovachr/locale/en.json", load: () => Promise.resolve(locale__app_apps_poslovachr_locale_en_json), cache: true }],
  "hr": [{ key: "../../poslovachr/locale/hr.json", load: () => Promise.resolve(locale__app_apps_poslovachr_locale_hr_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "../generic/locale/en.json",
        "../poslovachr/locale/en.json"
      ]
    },
    {
      "code": "hr",
      "iso": "hr-HR",
      "name": "Hrvatski",
      "files": [
        "../poslovachr/locale/hr.json"
      ]
    }
  ],
  "defaultLocale": "hr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./locale",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "job/[jobId]": {
      "hr": "/posao/[jobId]",
      "en": "/job/[jobId]"
    },
    "job/[jobId]/[slug]/apply": {
      "hr": "/posao/[jobId]/[slug]/prijava",
      "en": "/job/[jobId]/[slug]/apply"
    },
    "job/[jobId]/[slug]/apply-external": {
      "hr": "/posao/[jobId]/[slug]/apply-external",
      "en": "/job/[jobId]/[slug]/apply-external"
    },
    "job/[jobId]/[slug]/details": {
      "hr": "/posao/[jobId]/[slug]",
      "en": "/job/[jobId]/[slug]"
    },
    "search/index": {
      "en": "/job-search",
      "hr": "/pretraga-poslova"
    },
    "calculator/salary": {
      "en": "/calculator/salary"
    },
    "calculator/author": {
      "en": "/calculator/royalty-contract"
    },
    "calculator/service": {
      "en": "/calculator/service-contract"
    },
    "auth/login": {
      "en": "/login",
      "hr": "/prijava"
    },
    "auth/logout": {
      "en": "/logout",
      "hr": "/odjava"
    },
    "my-profile": {
      "en": "/my-profile",
      "hr": "/moj-profil"
    },
    "my/settings": {
      "en": "/my-profile/settings",
      "hr": "/moj-profil/postavke"
    },
    "my/applications": {
      "en": "/my-profile/applications",
      "hr": "/moj-profil/prijave"
    },
    "my/favorite-jobs": {
      "en": "/my-profile/saved-jobs",
      "hr": "/moj-profil/spremljeni-poslovi"
    },
    "my/documents": {
      "en": "/my-profile/documents",
      "hr": "/moj-profil/dokumenti"
    },
    "my/notifications": {
      "en": "/my-profile/notifications",
      "hr": "/moj-profil/obavijesti"
    },
    "faq/index": {
      "en": "/faq",
      "hr": "/cesta-pitanja"
    },
    "faq/[category]": {
      "en": "/faq/[category]",
      "hr": "/cesta-pitanja/[category]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "../generic/locale/en.json"
      },
      {
        "path": "../poslovachr/locale/en.json"
      }
    ]
  },
  {
    "code": "hr",
    "iso": "hr-HR",
    "name": "Hrvatski",
    "files": [
      {
        "path": "../poslovachr/locale/hr.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
